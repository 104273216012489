<!--
 * @Author: your name
 * @Date: 2021-11-19 09:41:39
 * @LastEditTime: 2022-07-25 21:23:39
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/layout/index.vue
-->
<template>
    <div class="layout-box">
        <!-- <van-action-sheet v-model="$store.state.show" :actions="actions" @select="onSelect" /> -->
        <van-popup v-model="$store.state.show" position="right" :style="{width:'50%',height:'100%'}">
            <div class="item_name" @click="goPage(item)" :class="item.activeMenu ? 'is-active' : null" v-for="(item,index) in routerMenu" :key="index">
                {{item.name}}
            </div>
            <div class="item_name" @click="onSelect">
                退出登录
            </div>
        </van-popup>
        <!-- <keep-alive>
            <router-view :key="this.$route.path" />
        </keep-alive> -->
        <keep-alive>
            <router-view :key="this.$route.path" v-if="this.$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!this.$route.meta.keepAlive" :key="this.$route.path" />
    </div>
</template>
<script>
    import routers from "@/router/index.js";
    import {logout} from "@/api/login/index"
    export default{
        data() {
            return {
                show:this.showMore,
                actions: [{ name: '退出登录', value:"1" }],
                routerMenu:[],
            }
        },
        methods:{
            goPage(item){
                if(item.name==="教案"){
                    let classInfo = this.$storeLocal.get("classInfo");
                    console.log("学年信息",classInfo)
                    if(!classInfo){
                        this.$notify( {type: 'danger', message: '当前学年没有班级,请联系管理员配置班级信息' });
                        return
                    }
                    this.$router.push({
                        name:"list",
                        params:{ id:classInfo.schoolYear.classId,schoolYearId:classInfo.schoolYear.id}
                    })
                }else{
                    console.log("11")
                    this.$router.push(item.path);
                }
                this.$store.commit("setIsShowLogo",false);
                
            },
            onSelect() {
                    this.$store.commit("setIsShowLogo",false);
                    logout().then(res =>{
                        if (res.code == 200) {
                            // localStorage.clear();
                            console.log(localStorage)
                            localStorage.removeItem('courseToken');
                            localStorage.removeItem('classInfo');
                            localStorage.removeItem('schoolData');
                            localStorage.removeItem('sendClass');
                            this.routerMenu.forEach(item => {
                                item.activeMenu = false;
                            })
                            this.$router.push("/login");
                        }else{
                            this.$notify( {type: 'danger', message: res.data.msg });
                        }
                    })
            },
        },
        watch: {
            // $route(to, from) {
            //     console.log(to,from);
            //     let filterpath = `${location.hash.slice(1).split("/")[1]}`;
            //     this.routerMenu.forEach((item) => {
            //         if (filterpath == item.path.split("/")[1]) {
            //         item.activeMenu = true;
            //         } else {
            //         item.activeMenu = false;
            //         }
            //     });
            // },

            $route:{
                handler:function(to){
                    console.log("8888888888",to)
                    this.routerMenu = routers.options.routes[2].children;
                    let filterpath = `${location.hash.slice(1).split("/")[1]}`;
                    this.routerMenu.forEach((item) => {
                        if (filterpath == item.path.split("/")[1]) {
                            item.activeMenu = true;
                        } else {
                            item.activeMenu = false;
                        }
                    });
                },
                immediate: true
            }
        },
        mounted() {
            // this.routerMenu = routers.options.routes[2].children;
            // this.routerMenu[0].activeMenu = true;
            // let filterpath = `${location.hash.slice(1).split("/")[1]}`;
            // console.log("sadjska",this.routerMenu);
            // console.log(filterpath)
        }
    }
</script>
<style lang="scss" scoped>
    .layout-box{
        height: 100%;

        .van-popup{
            padding: 130px 0;
            background-color: #4FB9B8;
        }

        .item_name{
            width: 100%;
            text-align: center;
            color: #9ED8D4;
            font-size: 40px;
            line-height: 120px;
            font-weight: bold;
        }

        .is-active{
            color: #FFFFFF;
            background-color: #6FC1BF;
        }
    }
</style>

